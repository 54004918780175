import { Box } from "@mui/material";
import React from "react";

export default function Maintenance() {
	return (
		<div>
			<Box sx={{ mb: 6, textAlign: "center" }}>
				<h5>The Fortune Factory will be open for Business soon!</h5>
			</Box>
		</div>
	);
}
