import React from "react";
import { toast } from "react-toastify";
import { Image } from "react-bootstrap";
import { utils } from "near-api-js";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Button, Stack, Grid, IconButton, Typography, TextField, Item } from "@mui/material";

import CookieStatic from "../assets/cookie_static.png";
import stakedStatic from "../assets/staked.png";
import CookieGif from "../assets/cookie_gif.gif";
import ExcitingGif from "../assets/feline_coins.gif";
import tokenStatic from "../assets/token.png";
import factoryBanner from "../assets/factoryBanner.png";
import poolBanner from "../assets/vaultBanner.png";
const NORMAL_GAS = "150000000000000";

export default function StakeComponent() {
	const [totalStaked, setTotalStaked] = React.useState(0);
	const [nekoBalance, setNekoBalance] = React.useState(0);
	const [accReward, setAccReward] = React.useState(0);
	const [lastUpdate, setLastUpdate] = React.useState(0);
	const [cookieBalance, setCookieBalance] = React.useState(0);
	const [contractBalance, setContractBalance] = React.useState(0);
	const [isRegisteredCookie, setIsRegisteredCookie] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [fuckingExcited, setFuckingExcited] = React.useState(false);
	const [estReward, setEstReward] = React.useState(0);
	const amountRef = React.useRef(null);
	const stakeAmountRef = React.useRef(null);

	const exchangeAmountRef = React.useRef(null);
	React.useEffect(() => {
		if (!window.accountId) return;
		window.contract_factory
			.storage_balance_of({ account_id: window.accountId })
			.then((res) => {
				setIsRegisteredCookie(res);
			})
			.catch((e) => {
				console.log(e.message);
			});
		window.contract_factory
			.ft_balance_of({ account_id: window.accountId })
			.then((res) => {
				setCookieBalance(res);
			})
			.catch((e) => {
				console.log(e.message);
			});
		window.contract.ft_balance_of({ account_id: window.accountId }).then((res) => {
			setNekoBalance(res);
		});
		window.contract_factory.ft_balance_of({ account_id: process.env.REACT_APP_NEKO_CONTRACT_ID }).then((res) => {
			setContractBalance(res);
		});
		window.contract.cal_stake_reward_by_account({ account_id: window.accountId }).then((res) => {
			console.log("cal reward:", res);
			setEstReward(res);
		});
		getStakeData();
	}, [window.accountId]);
	const getStakeData = async () => {
		try {
			const stakeData = await window.contract.get_stake_by_id({ id: window.accountId });
			setTotalStaked(stakeData.total_stake);
			setAccReward(stakeData.acc_reward);
			setLastUpdate(stakeData.last_update_time);
		} catch (e) {
			console.error(e.message);
		}
	};
	const updateStakeData = async () => {
		setIsLoading(true);
		try {
			await toast.promise(window.contract.update_stake_data({ account_id: window.accountId }), {
				pending: "Loading",
				success: "success",
			});
			getStakeData();
		} catch (e) {
			toast.error(e.message);
		}
		setIsLoading(false);
	};
	const openCookieAccount = async () => {
		await toast.promise(
			window.contract_factory.storage_deposit(
				{ account_id: window.accountId },
				"300000000000000",
				utils.format.parseNearAmount("0.01")
			),
			{
				pending: "Opening...",
				error: `${accountId == "" ? "Connect your Wallet First" : "Error..."}`,
			}
		);
		getStakeData();
	};
	const ftMint = async () => {
		setIsLoading(true);
		console.log(amountRef.current.value);
		try {
			toast.promise(
				window.contract.ft_mint({
					to: window.accountId,
					amount: parseInt(amountRef.current.value),
				}),
				{ pending: "Minting Test Neko", success: "Minted Test Neko", error: "Error Minting Test Neko" }
			);
		} catch (e) {
			toast.error(e.message);
		}
		setIsLoading(false);
	};
	const stake = async () => {
		setIsLoading(true);
		console.log(stakeAmountRef.current.value);
		if (cookieBalance < parseInt(stakeAmountRef.current.value)) {
			toast.error("You don't have enough Cookie!");
			return;
		}
		try {
			await toast.promise(
				window.contract.stake(
					{
						amount: parseInt(stakeAmountRef.current.value),
					},
					"180000000000000",
					1
				),
				{ pending: "Staking Test Neko", success: "Staked Test Neko", error: "Error Staking Test Neko" }
			);
		} catch (e) {
			toast.error(e.message);
		}

		setIsLoading(false);
	};
	const unstake = async () => {
		setIsLoading(true);

		if (totalStaked < stakeAmountRef.current.value) {
			toast.error("You don't have enough staked Cookie!");
			return;
		}

		try {
			await toast.promise(
				window.contract.unstake(
					{
						amount: parseInt(stakeAmountRef.current.value),
					},
					"180000000000000",
					1
				),
				{ pending: "Unstaking Neko", success: "Unstaked  Neko", error: "Error Unstaking  Neko" }
			);
		} catch (e) {
			toast.error(e.message);
		}

		setIsLoading(false);
	};
	const claimReward = async () => {
		setIsLoading(true);
		try {
			await toast.promise(
				window.contract.claim_neko(
					{
						account_id: window.accountId,
					},
					"200000000000000",
					1
				),
				{ pending: "Claiming Reward", success: "Claimed Reward", error: "Error Claiming Reward" }
			);
		} catch (e) {
			toast.error(e.message);
		}

		setIsLoading(false);
	};
	const bake = async () => {
		setIsLoading(true);
		console.log(nekoBalance, exchangeAmountRef.current.value);
		if (nekoBalance < exchangeAmountRef.current.value) {
			toast.error("You don't have enough Neko!");
			return;
		}
		try {
			await toast.promise(
				window.contract.bake({
					args: { amount: parseInt(exchangeAmountRef.current.value) },
					amount: 2,
					gas: NORMAL_GAS,
				}),
				{
					pending: "Baking Neko",
					success: "Baked Neko",
					error: "Error Baking Neko",
				}
			);
		} catch (e) {
			console.log(e.message);
		}
	};
	const unbake = async () => {
		setIsLoading(true);

		if (cookieBalance < parseInt(exchangeAmountRef.current.value)) {
			toast.error("You don't have enough Cookie!");
			return;
		}
		try {
			await toast.promise(
				window.contract.unbake({
					args: { amount: parseInt(exchangeAmountRef.current.value) },
					amount: 1,
					gas: NORMAL_GAS,
				}),
				{ pending: "Unbaking Neko", success: "Unbaked Neko", error: "Error Unbaking Neko" }
			);
		} catch (e) {
			console.log(e.message);
		}
	};

	const parseEstReward = (rewardRate, lastUpdateTime, accReward) => {
		const updateInterval = 1200;
		const jsTimeNowToNearTime = Date.now() * 1000000;
		let final = 0;
		if (rewardRate > 0) {
			final = Math.floor(
				(rewardRate * ((jsTimeNowToNearTime - lastUpdateTime) / (1000000000 * updateInterval))) / rewardRate
			);
		}
		return Math.floor(accReward + final * rewardRate);
	};
	return (
		<div>
			{isRegisteredCookie ? (
				<Box sx={{ mb: 6 }}>
					<Grid container spacing={2} justifyContent="center" display={"flex"} flexDirection={"column"}>
						<Grid item justifyItems={"center"} alignItems={"center"} sx={{ textAlign: "center" }}>
							<Image src={factoryBanner} width={"35%"} style={{ borderRadius: 30 }} />
						</Grid>

						<Grid item mt={3}>
							<Stack direction="row" justifyContent={"center"} alignItems={"center"}>
								<Image src={tokenStatic} width={30} /> x {nekoBalance}
								<IconButton onClick={unbake}>
									<ArrowLeftIcon />
								</IconButton>
								<TextField
									label="Exchange"
									variant="outlined"
									inputRef={exchangeAmountRef}
									type="number"
									defaultValue={"0"}
									style={{ width: 100 }}
								/>
								<IconButton onClick={bake}>
									<ArrowRightIcon />
								</IconButton>
								<Image src={CookieStatic} width={60} />x {cookieBalance}
							</Stack>
						</Grid>
						<hr />
						<Grid item justifyItems={"center"} alignItems={"center"} sx={{ textAlign: "center" }}>
							<Image src={poolBanner} width={"45%"} style={{ borderRadius: 20 }} />
						</Grid>

						<Grid item>
							<Stack direction="row" justifyContent={"center"} alignItems={"center"}>
								<Image src={CookieStatic} width={60} />x{cookieBalance}
								<IconButton onClick={unstake}>
									<ArrowLeftIcon />
								</IconButton>
								<TextField
									label="Stake/Unstake"
									variant="outlined"
									inputRef={stakeAmountRef}
									type="number"
									defaultValue={"100"}
									style={{ width: 150 }}
								/>
								<IconButton onClick={stake}>
									<ArrowRightIcon />
								</IconButton>
								<p>
									Staked: <br />
									{totalStaked}x<Image src={stakedStatic} width={45} />
								</p>
							</Stack>
							<Grid item sx={{ pt: 4 }}>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<Box
										sx={{
											minWidth: "350px",
											height: "auto",

											textAlign: "center",
											alignSelf: "center",
										}}
									>
										<Grid container spacing={2} justifyContent="center">
											<Grid item xs={12}>
												<p>
													Est Accrued Reward: <br />
													{Math.floor(accReward + estReward)}
													{/* {parseEstReward(totalStaked * 0.05, lastUpdate, accReward)}{" "} */}
													<Image src={tokenStatic} width={30} />
												</p>
												{fuckingExcited && <Image src={ExcitingGif} width={30} />}
												<Button
													style={{ background: "#7e181d", color: "#fdb55a" }}
													onClick={claimReward}
													onMouseEnter={() => setFuckingExcited(true)}
													onMouseLeave={() => setFuckingExcited(false)}
												>
													Claim NEKO
												</Button>
												{fuckingExcited && <Image src={ExcitingGif} width={30} />}
											</Grid>
										</Grid>
									</Box>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			) : (
				<>
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={12} sx={{ textAlign: "center" }}>
							<Grid item sx={{ pt: 4 }}>
								<Box sx={{ minWidth: "250px" }}>
									<Typography variant="subtitle1">Welcome!</Typography>
								</Box>
							</Grid>
							<Grid item sx={{ pt: 4, pb: 4 }}>
								<Button variant="outlined" onClick={openCookieAccount}>
									Open Cookie Account
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</>
			)}
		</div>
	);
}
