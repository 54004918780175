import React from "react";
import { utils } from "near-api-js";
import { Image, Stack } from "react-bootstrap";
import { Box, Grid, Dialog, Slide, DialogContent, IconButton } from "@mui/material";
import StakeComponent from "./StakeComponent";
import NFTStake from "./NFTStake";
import { toast } from "react-toastify";
import Maintenance from "./Maintenance";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import factoryRibbon from "../assets/factoryRibbon.png";
import poolRibbon from "../assets/poolRibbon.png";
import CloseIcon from "@mui/icons-material/Close";
export default function Landing() {
	const factoryImageRef = React.useRef(null);
	const nftImageRef = React.useRef(null);
	const [isFactoryModalOpen, setIsFactoryModalOpen] = React.useState(
		localStorage.getItem("isFactoryModalOpen") === "true" || false
	);
	const [isNFTModalOpen, setIsNFTModalOpen] = React.useState(
		localStorage.getItem("isNFTModalOpen") === "true" || false
	);
	const [ftContractModalOpen, setFtContractModalOpen] = React.useState(false);
	const [nftContractModalOpen, setNftContractModalOpen] = React.useState(false);
	const factoryHover = (e) => {
		e.target.src = require("../assets/house/factory_hover.gif");
		nftImageRef.current.src = require("../assets/house/nft_before.png");
	};
	const nftHover = (e) => {
		e.target.src = require("../assets/house/nft_hover.gif");
		factoryImageRef.current.src = require("../assets/house/factory_before.png");
	};

	return (
		<Box
			sx={{
				height: { lg: "100vh", xs: "100%" },
				background: {
					lg: `url(${require("../assets/background/background.png")})`,
					xs: `url(${require("../assets/background/mobilebg.jpg")})`,
				},
				backgroundSize: { lg: "100% 100%", xs: "cover" },
			}}
		>
			<Box sx={{ textAlign: "center", padding: 5 }}>
				<img
					src={require("../assets/logo.png")}
					alt="logo"
					width="250px"
					className="animate__animated animate__bounceInDown "
				/>
			</Box>
			<Grid container spacing={2} justifyContent="center">
				<Grid item>
					<Grid container display={"flex"} flexDirection={"column"} alignItems={"center"}>
						<Grid item>
							<Box
								sx={{
									position: { lg: "absolute", md: "relative" },
									top: { lg: "40%" },
									right: { lg: "60%" },
									zIndex: "1002",
								}}
							>
								<img
									src={factoryRibbon}
									alt="factory ribbon"
									style={{ width: "400px" }}
									className="animate__animated animate__rubberBand animate__delay-1s"
								/>
								<div style={{ textAlign: "center" }}>
									<IconButton
										onClick={() => {
											setFtContractModalOpen(true);
										}}
									>
										<HelpOutlineIcon />
									</IconButton>
									<p style={{ color: "white" }}>Click On Factory To Stake Cookie!</p>
								</div>
							</Box>
						</Grid>

						<Grid item>
							<Box sx={{ position: { lg: "absolute", md: "relative" }, bottom: { lg: -50 }, right: { lg: "55%" } }}>
								<img
									src={require("../assets/house/factory_before.png")}
									ref={factoryImageRef}
									alt="nft"
									width="100%"
									onMouseEnter={(e) => factoryHover(e)}
									onClick={() => {
										if (!window.accountId) {
											toast.info("Please login to continue");
											return;
										}
										setIsFactoryModalOpen(true);
										localStorage.setItem("isFactoryModalOpen", true);
									}}
									style={{ cursor: "pointer" }}
								/>
							</Box>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Grid container display={"flex"} flexDirection={"column"} alignItems={"center"}>
						<Grid item>
							<Box
								sx={{
									position: { lg: "absolute", md: "relative" },
									top: { lg: "40%" },
									left: { lg: "60%" },
									zIndex: "1002",
								}}
							>
								<img
									src={poolRibbon}
									alt="pool ribbon"
									style={{ width: "400px" }}
									className="animate__animated animate__rubberBand animate__delay-2s"
								/>
								<div style={{ textAlign: "center" }}>
									<IconButton
										onClick={() => {
											setNftContractModalOpen(true);
										}}
									>
										<HelpOutlineIcon />
									</IconButton>
									<p style={{ color: "white" }}>Click On Image To Stake NFT!</p>
								</div>
							</Box>
						</Grid>
						<Grid item>
							<Box sx={{ position: { lg: "absolute", md: "relative" }, bottom: { lg: -50 }, left: { lg: "55%" } }}>
								<img
									src={require("../assets/house/nft_before.png")}
									alt="nft"
									ref={nftImageRef}
									width="100%"
									onMouseEnter={(e) => nftHover(e)}
									onClick={() => {
										if (!window.accountId) {
											toast.info("Please login to continue");
											return;
										}
										setIsNFTModalOpen(true);
										localStorage.setItem("isNFTModalOpen", true);
									}}
									style={{ cursor: "pointer" }}
								/>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Dialog
				open={isFactoryModalOpen}
				TransitionComponent={Transition}
				onClose={() => {
					setIsFactoryModalOpen(false);
					localStorage.setItem("isFactoryModalOpen", false);
				}}
				fullWidth
				maxWidth="xl"
				PaperProps={{
					style: {
						backgroundColor: "rgba(12,12,12,0.8)",
						boxShadow: "120px",
						borderRadius: "12px",
						border: "5px ridge #eeeeee",
					},
				}}
			>
				<DialogContent>
					<IconButton
						onClick={() => {
							setIsFactoryModalOpen(false);
							localStorage.setItem("isFactoryModalOpen", false);
						}}
						style={{ position: "absolute", right: 0, top: 0 }}
					>
						<CloseIcon sx={{ width: "9vmin", height: "9vmin", color: "#ebad55" }} />
					</IconButton>
					<Maintenance />
					{/* <StakeComponent /> */}
				</DialogContent>
			</Dialog>
			<Dialog
				open={isNFTModalOpen}
				TransitionComponent={Transition}
				onClose={() => {
					setIsNFTModalOpen(false);
					localStorage.setItem("isNFTModalOpen", false);
				}}
				fullWidth
				maxWidth="xl"
				PaperProps={{
					style: {
						backgroundColor: "rgba(12,12,12,0.8)",
						boxShadow: "120px",
						overflow: "auto",
						/* borderRadius: "20px", */
						/* 	border: "5px ridge #eeeeee", */
					},
				}}
			>
				<DialogContent>
					<IconButton
						onClick={() => {
							setIsNFTModalOpen(false);
							localStorage.setItem("isNFTModalOpen", false);
						}}
						style={{ position: "absolute", right: 0, top: 0 }}
					>
						<CloseIcon sx={{ width: "9vmin", height: "9vmin", color: "#ebad55" }} />
					</IconButton>
					<NFTStake />
				</DialogContent>
			</Dialog>
			<Dialog
				open={ftContractModalOpen}
				TransitionComponent={Transition}
				onClose={() => {
					setFtContractModalOpen(false);
				}}
				fullWidth
				maxWidth="md"
				PaperProps={{
					style: {
						backgroundColor: "rgba(12,12,12,0.8)",
						boxShadow: "120px",
						borderRadius: "12px",
						border: "5px ridge #eeeeee",
					},
				}}
			>
				<DialogContent sx={{ textAlign: "center" }}>
					<h3>Neko Fortune Factory Contract</h3>
					<p>Bake your NEKO into COOKIE and stake your COOKIE into NEKO rewards</p>
				</DialogContent>
			</Dialog>
			<Dialog
				open={nftContractModalOpen}
				TransitionComponent={Transition}
				onClose={() => {
					setNftContractModalOpen(false);
				}}
				fullWidth
				maxWidth="md"
				PaperProps={{
					style: {
						backgroundColor: "rgba(12,12,12,0.8)",
						boxShadow: "120px",
						borderRadius: "12px",
						border: "5px ridge #eeeeee",
					},
				}}
			>
				<DialogContent sx={{ textAlign: "center" }}>
					<h3>Neko Fortune Pool Contract</h3>
					<p>Stake your Good Fortune Felines NFTs for NEKO rewards!</p>
				</DialogContent>
			</Dialog>
		</Box>
	);
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
